export enum UserType {
    BUSINESS = 'business',
    USER = 'user'
}
export enum BusinessType{
    CORPORATE = 'corporate',
    SME = 'sme-business',
    SMB = 'smb-business',
    ENTREPRENEUR = 'entrepreneur',
}
export enum CurrencyType {
    NGN = 'NGN',
    USD = 'USD'
}
export enum PrefixType{
    PRODUCT = 'VPD'
}
export enum AmountType{
    FIXED = 'fixed',
    DYNAMIC = 'dynamic'
}
export enum TransactionStatus{
    PENDING = 'pending',
    PROCESSING = 'processing',
    SUCCESSFUL = 'successful',
    COMPLETED = 'completed',
    FAILED = 'failed',
    REFUNDED = 'refunded',
    PAID = 'paid',
    CANCELLED = 'cancelled',
    OVERDUE = 'overdue',
    DECLINED = 'declined',
}
export enum ProviderNameType {
    BANI = 'bani',
    PAYAZA = 'payaza',
    PAYSTACK = 'paystack',
    FLUTTERWAVE = 'flutterwave',
    NINEPSB = 'ninepsb'
}
export enum FeatureType{
    INVOICE = 'invoice',
    PRODUCT = 'product',
    REQUEST = 'request'
}
export enum VerificationType{
    PENDING = 'pending',
    SUBMITTED = 'submitted',
    APPROVED = 'approved',
    DECLINED = 'declined',
    ONHOLD = 'on-hold',
}
export enum ValueType{
    PERCENTAGE = 'percentage',
    FLAT = 'flat'
}
export enum LoginType{
    EMAIL = 'email',
    BIOMETRIC = 'biometric'
}
export enum OnboardType{
    PENDING = 'pending',
    BASIC = 'basic',
    ADDRESS = 'address',
    COMPANY = 'company-info',
    FACEID = 'face-id',
    IDCARD = 'id-card',
    OWNER = 'owner-info',
    BANK = 'bank-details',
    BVN = 'bvn-number',
    NIN = 'nin-number',
    LIVENESS = 'liveness-check',
    PIN = 'transaction-pin',
    QUESTION = 'security-question'
}

export enum SaveActionType {
    SAVE = "save-new",
    UPDATE = "update-data"
}

export enum SettleIntoType{
    WALLET = 'wallet',
    BANK = 'bank'
}
export enum CheckoutDisplayType{
    PAYMENT = 'payment',
    METHODS = 'methods',
    USER_INFO = 'user-info'
}
export enum CheckoutOptionType{
    CARD = 'card',
    BANK_TRANSFER = 'bank-transfer',
    BANK_ACCOUNT = 'bank-account'
}
export enum HeaderType {
    IDEMPOTENT = 'x-idempotent-key'
}
export enum CookieKeyType {
    XHIT = 'x-hit'
}