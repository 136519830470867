import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import loader from './helpers/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorUI from './components/global/ErrorUI';

import DashboardLayout from './components/layouts/Dashboard';

import ResourceState from './context/resource/resourceState';
import UserState from './context/user/userState';
import VaceState from './context/vace/vaceState';
import SocketState from './context/socket/socketState'

// URLS
const Homepage = React.lazy(() => import('./pages/Home'));
const PaymentPage = React.lazy(() => import('./pages/payment/Pay'));
const VerifyPaymentPage = React.lazy(() => import('./pages/payment/Verify'));
const NetworkUI = React.lazy(() => import('./components/global/NetworkUI'));
const CheckoutPage = React.lazy(() => import('./pages/checkout/Checkout'));
const InvoicePreview = React.lazy(() => import('./pages/checkout/InvoicePreview'));


const App = () => {

  const errorHandler = (err: any, info: any) => {
    console.log(err, 'logged error');
    console.log(info, 'logged error info');
  }

  return (

    <Router>

      <UserState>

        <SocketState>

            <VaceState>

              <ResourceState>

                <Suspense fallback={loader.MainLoader()}>

                  <ErrorBoundary FallbackComponent={ErrorUI} onReset={() => { window.location.reload() }} onError={errorHandler}>

                    <Routes>

                      {/* Auth Routes */}
                      <Route path='/home' element={<PaymentPage />} />
                      <Route path='/' element={<PaymentPage />} />
                      <Route path='/no-network' element={<NetworkUI />} />
                      <Route path='/link/:label' element={<PaymentPage />} />
                      <Route path='/verify' element={<VerifyPaymentPage />} />
                      <Route path='/invoice/:code' element={<InvoicePreview />} />

                    </Routes>

                  </ErrorBoundary>

                </Suspense>

              </ResourceState>

            </VaceState>

        </SocketState>

      </UserState>

    </Router>

  )

}

export default App;